import axios from "axios";
import { parseAxiosError } from "components/common/constant";
import { OLD_NATIONAL_GAMES_API, NEW_NATIONAL_GAMES_API } from "./commonapi";

const API_BASE = process.env.REACT_APP_BASE_URL;
// const API_BASE = "https://admin.37nationalgamesgoa.in/api";

export const fetchAllSports = async () => {
    try {
      const response = await axios({
        method: "get",
        url: API_BASE + `/api/get_all_sports_data`,
      });
      // console.log("response===>",response)
      if (response?.status === 200) {
        const data = {
          data: response?.data,
          status: response?.status,
        };
  
        return data;
      }
  
      return null;
    } catch (error) {
      const err = parseAxiosError(error);
  
      const data = {
        error: err?.error?.response?.data,
        message: err?.error?.response?.data?.message,
        status: err?.error?.response?.status,
      };
  
      return data;
    }
};

export const fetchSchedule = async ({ sport_id, gender, cluster, date }) => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/api/mock/schedule`,
      params: sport_id || gender || cluster || date ? { sport_id, gender, cluster, date } : undefined, // Add params only if filters exist
    });

    if (response?.status === 200) {
      return {
        data: response?.data,
        status: response?.status,
      };
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    return {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };
  }
};

export const fetchClusterSchedule = async ({ sport_id, cluster_name }) => {
  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/api/get_all_sports_data`,
      params: sport_id || cluster_name ? { sport_id, cluster_name } : undefined, // Add params only if filters exist
    });

    if (response?.status === 200) {
      return {
        data: response?.data,
        status: response?.status,
      };
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    return {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };
  }
};

export const fetchAllStateName = async () => {

  try {
    const response = await axios({
      method: "get",
      url: API_BASE + `/api/get_all_states_data`,
    });
    if (response?.status === 200) {
      const data = {
        data: response?.data,
        status: response?.status,
      };

      return data;
    }

    return null;
  } catch (error) {
    const err = parseAxiosError(error);

    const data = {
      error: err?.error?.response?.data,
      message: err?.error?.response?.data?.message,
      status: err?.error?.response?.status,
    };

    return data;
  }
};
