import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import torch from "./torch.png";
import { getAllSportsData } from "redux/reducers/sportsReducer";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import racer from "../../assets/home2/Cluster/logo-color.png";

const SportSchedule = ({ dateArray, startIndex, visibleDates }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSportsData());
  }, [dispatch]);

  const { allSportsData: sportsData } = useSelector(
    (state) => state.sportsReducer
  );

  const history = useHistory();

  const handleClickRoute = (item) => {
    let transformedName = item;

    if (transformedName === "kayaking_and_canoeing") {
      transformedName = "canoeing";
    } else if (transformedName === "rugby_seven") {
      transformedName = "rugby";
    } else if (transformedName == "shooting") {
      window.location.href =
        "https://tsr.38nguk.in/winner-circle/sport/shooting";
    }  else if (transformedName == "modern_pentathlon" || transformedName == "modern-pentathlon") {
      window.location.href =
        "https://tsr.38nguk.in/scoring-scheduling/sport/modern_pentathlon";
    } else {
      transformedName = transformedName?.replace(/_/g, '-');
    }
    history.push(`/sports/${transformedName}`);
  };
  // Static entries for Opening and Closing Ceremonies
  const staticEntries = [
    {
      id: 1,
      sport_name: "Opening & Closing Ceremonies",
      date: ["28 January 2025", "14 February 2025"],
      start_date: null,
      end_date: null,
      sport_icon_url: racer,
    },
  ];

  // Combine static entries with dynamic sports data
  const combinedSportsData = [...staticEntries, ...(sportsData || [])];

  return (
    <Container fluid>
      {combinedSportsData.map((item, index) => (
        <Row className="mb-3 mt-3" key={index}>
          <Col lg={2} className="text-start">
            <div
              className={`d-flex align-items-center ${item?.sport_name === "Opening & Closing Ceremonies"
                  ? ""
                  : "cursor-pointer"
                }`}
              onClick={
                item?.sport_name === "Opening & Closing Ceremonies"
                  ? undefined
                  : () => handleClickRoute(item?.rf_sport_db_name)
              }
            >
              <div>
                {/* <img
                  loading="lazy"
                  src={item?.sport_icon_url}
                  alt={`Icon for ${item?.sport_name}`}
                  className={`rounded-2 ${
                    item?.sport_name === "Opening & Closing Ceremonies"
                      ? "m-0"
                      : "m-2"
                  }`}
                  style={
                    item?.sport_name === "Opening & Closing Ceremonies"
                      ? {
                          width: "100px",
                          height: "60px",
                          objectFit: "contain",
                          padding: "2px",
                        }
                      : {
                          width: "75px",
                          height: "85px",
                          objectFit: "contain",
                          padding: "10px",
                          borderRadius: "10px",
                          // background: "#BE0105",
                        }
                  }
                /> */}
                {item?.sport_name !== "Opening & Closing Ceremonies" && (
                  <img
                    loading="lazy"
                    src={item?.sport_icon_url}
                    alt={`Icon for ${item?.sport_name}`}
                    className="rounded-2 mx-1"
                    style={{
                      width: "70px",
                      height: "80px",
                      objectFit: "contain",
                      borderRadius: "10px",
                    }}
                  />
                )}

              </div>
              <div>
                <p

                  className="schedule_sport_name"
                >
                  {item?.sport_name}
                </p>
              </div>
            </div>
          </Col>
          <Col
            lg={10}
            className="d-flex justify-content-center align-items-center"
          >
            <Row className="w-100 flex-wrap">
              <div className="col-span-8 items-center d-flex gap-2 w-100">
                <div className="col-span-1 items-center d-flex gap-2 w-100 justify-content-between mb-3 mb-lg-0">
                  {dateArray
                    ?.slice(startIndex, startIndex + visibleDates)
                    ?.map((date, dateId) => {
                      const formattedDate = dayjs(date, "DD-MMMM-YYYY");
                      const isWithinEventRange =
                        dayjs(formattedDate).isAfter(
                          dayjs(item?.start_date, "DD MMMM YYYY").subtract(
                            1,
                            "day"
                          )
                        ) &&
                        dayjs(formattedDate).isBefore(
                          dayjs(item?.end_date, "DD MMMM YYYY").add(1, "day")
                        );

                      const isTorchDate =
                        item?.sport_name === "Opening & Closing Ceremonies" &&
                        item?.date?.some(
                          (torchDate) =>
                            dayjs(torchDate, "DD MMMM YYYY").format(
                              "DD-MMMM-YYYY"
                            ) === date
                        );

                      return (
                        <div
                          key={dateId + "-dot"}
                          className="w-100 text-center flex justify-content-center align-content-center position-relative"
                        >
                          <Col className="d-flex justify-content-center sport_cube_col">
                            <div
                              className={`${isWithinEventRange || isTorchDate
                                  ? "sport_cube_box"
                                  : "sport_cube_box_white"
                                }`}
                            >
                              {isTorchDate ? (
                                <img src={torch} alt="Torch Icon" />
                              ) : isWithinEventRange ? (
                                <div
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "50%",
                                    backgroundColor: "rgba(157, 45, 32, 1)",
                                  }}
                                ></div>
                              ) : null}
                            </div>
                          </Col>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default SportSchedule;
