import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import NavBar from "./components/navbar/NavBar";
import NavBar from "./components/home2/Header/index";
import Footer2 from "./components/footer2_new";
// import GamePartners from "components/home2/GamePartners/GamePartners";
// import UtterakhandClock from "./components/home2/utterakhandClock";
// import { partnersList } from "./components/home2/GamePartners/dataList";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import ReactGA from "react-ga";
import { getMedia, getNews } from "redux/reducers/mediaReducer";
import ScheduleOverview from "components/schedule/overview";
import ErrorBoundary from "components/ErrorBoundary";
import { getMilestoneCategory } from "redux/reducers/milestoneReducer";
import { getPlayersList } from "redux/reducers/playersReducer";


// const Home = lazy(() => import("./components/home/Home"));
const Home2 = lazy(() => import("./components/home2/Home"));


const Footerbottom = lazy(() => import("./components/footer/Footerbottom"));
const Records = lazy(() => import("./components/records"));
const Reports = lazy(() => import("./components/reports"));
const Hospitality = lazy(() => import("./components/hospitality"));
const Travel = lazy(() => import("./components/travel"));
const NationalGames = lazy(() => import("./components/nationalGames"));
const Photos = lazy(() => import("./components/Photos"));
const ParticipantTeam = lazy(() => import("./components/participantTeam"));
const SpecificState = lazy(() =>
  import("./components/participantTeam/specificState")
);
// const SportsPage = lazy(() => import("./components/sports"));
const ComingSoon = lazy(() =>
  import("./components/home2/ComingSoon/comingsoon")
);
const DailySchedule = lazy(() =>
  import("./components/schedule/DailySchedule/DailySchedule")
);
const SchedulePage = lazy(() => import("./components/schedule/SchedulePage"));
const ScheduleAndResults = lazy(() => import("./components/schedule"));
const AboutusRoute = lazy(() => import("./modules/aboutUs/route"));
const NewsSection = lazy(() => import("./modules/news"));
const NewsInsideSection = lazy(() => import("./modules/news/insideSection"));
const MilestoneSection = lazy(() => import("./modules/milestone"));
const MilestoneInsideSection = lazy(() =>
  import("./modules/milestone/insideSection")
);
const LatestVideo = lazy(() => import("./modules/video"));
// const LatestVideoInfo = lazy(() => import("./modules/video/videoInfo"));

const SportsInside = lazy(() => import("./modules/sportsInside"));
const PrivacyPolicy = lazy(() =>
  import("./components/privacyPolicy/PrivacyPolicy")
);
const ViewCompetitionZone = lazy(() =>
  import("./components/viewCompetitionZone/ViewCompetitionZone")
);
const ViewScoreSingle = lazy(() =>
  import("modules/sportsInside/viewScore/single")
);
const ViewScoreTeam = lazy(() => import("modules/sportsInside/viewScore/team"));
const SportsLeague = lazy(() => import("modules/sportsInside/league"));
const Elimination = lazy(() => import("modules/sportsInside/elimination"));
const Leaderboard = lazy(() => import("modules/sportsInside/leaderboard"));
const EventDetail = lazy(() => import("modules/sportsInside/eventDetails"));
const Team = lazy(() => import("./components/team/Team"));
const MedalTally  = lazy(() => import("./components/medalTally"));
const FAQ = lazy(() => import("./components/home2/FAQpage"));
const ScheduleRecord = lazy(() => import("./components/home2/Schedule"));
const SportCard = lazy(() => import("modules/sportsInside/sportCard/SportCard.jsx"));
const GreenGames = lazy(() => import("./components/home2/GreenGames"))
function App() {


  ReactGA.initialize("G-FX61GEFV5C");
  const queryClient = new QueryClient();
  const { hideFooter, removeHeaderAndFooter } = useSelector(
    (state) => state.mapReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.pathname === "/general-volunteer-registration") {
      window.location.href =
        "https://gms.38nguk.in/general-volunteer-registration";
    }
    ReactGA.pageview(window.location.pathname);
    dispatch(getNews());
    dispatch(getMedia());
    dispatch(getMilestoneCategory());
    dispatch(getPlayersList());
  }, []);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<></>}>
          <Router>
            {!removeHeaderAndFooter && <NavBar />}
            <Switch>
              <Route exact path="/" component={Home2} />
              <Route exact path="/games/medals" component={MedalTally} />
              <Route exact path="/games/records" component={Records} />
              <Route exact path="/games/reports" component={Reports} />
              <Route exact path="/visit/hospitality" component={Hospitality} />
              <Route exact path="/visit/travel" component={Travel} />
              <Route exact path="/visit/khel-village" component={ComingSoon} />
              <Route
                exact
                path="/visit/36-national-games"
                component={NationalGames}
              />
                 <Route
                exact
                path="/visit/national-games"
                component={NationalGames}
              />
              {/* <Route exact path="/info/committees" component={Committees} /> */}
              {/* <Route exact path="/teams" component={ParticipantTeam} /> */}

              {/* Team Page */}
              <Route exact path="/teams" component={Team} />
              <Route path="/teams/:state" component={SpecificState} />
              {/* Team Page End*/}

              <Route
                exact
                path="/competitionZone"
                component={ViewCompetitionZone}
              />
              <Route exact path="/mteams" component={ParticipantTeam} />
              <Route exact path="/mteams/:state" component={SpecificState} />
              <Route exact path="/allsports" component={SportCard} />
              <Route exact path="/sports/:name" component={SportsInside} />
              <Route exact path="/sports" component={SportsInside} />
              <Route
                exact
                path="/sports/:name/elimination"
                component={Elimination}
              />
              <Route exact path="/sports/:name/pool" component={EventDetail} />
              <Route
                exact
                path="/sports/:name/league"
                component={EventDetail}
              />
              <Route
                exact
                path="/sports/:name/leaderboard"
                component={Leaderboard}
              />
              <Route
                exact
                path="/sports/:name/view-score-single"
                component={ViewScoreSingle}
              />
              <Route
                exact
                path="/sports/:name/view-score-team"
                component={ViewScoreTeam}
              />
              <Route
                exact
                path="/sports/:name/league"
                component={SportsLeague}
              />
              <Route exact path="/schedule" component={ScheduleAndResults} />
              <Route exact path="/scheduleRecord" component={ScheduleRecord} />
              <Route
                exact
                path="/schedule/overview"
                component={ScheduleOverview}
              />
              <Route exact path="/schedule/cluster" component={SchedulePage} />
              <Route exact path="/schedule/daily" component={DailySchedule} />
              <Route exact path="/latest/news" component={NewsSection} />
              <Route exact path="/latest/photos" component={Photos} />
              <Route
                exact
                path="/latest/news/:id"
                component={NewsInsideSection}
              />
              <Route exact path="/latest/videos" component={LatestVideo} />
              <Route exact path="/faq" component={FAQ} />
              {/* <Route
                exact
                path="/latest/videos/:id"
                component={LatestVideoInfo}
              /> */}
              <Route path="/info/:id" component={AboutusRoute} />
              <Route path="/green-game" component={GreenGames} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                exact
                path="/milestone/:categoryName"
                component={MilestoneSection}
              />
              <Route
                exact
                path="/milestone/:categoryName/:id"
                component={MilestoneInsideSection}
              />

              <Route path="*" component={ComingSoon} />
            </Switch>

            {/* <GamePartners partnersList={partnersList} /> */}
            {/* <UtterakhandClock /> */}

            {/* {!hideFooter && !removeHeaderAndFooter && <Footer />} */}
            {!hideFooter && !removeHeaderAndFooter && <Footer2 />}

            {!removeHeaderAndFooter && <Footerbottom />}
          </Router>
        </Suspense>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
